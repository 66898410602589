import React from "react";
import Main from "../components/main";
import Seo from "../components/section/seo";
import MfaVerifyComponent from "../components/mfa-verify";

export default function Mfa() {
  return (
    <Main>
      <Seo title="SpeedyTel - Portal" />
      <MfaVerifyComponent/>
    </Main>
  );
}
