import React, {useState, useEffect} from "react";
import {useMutation} from "@apollo/client";
import root from "window-or-global";
import {denyVerificationMutation} from "./logic/user";
import {Button} from "react-bootstrap";

const MfaVerify = () => {
  const [responded, setResponded] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [otp, setOtp] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [denyVerification, {loading: denyVerificationLoading}] = useMutation(denyVerificationMutation);
  
  useEffect(() => {
    const urlQuery = new URLSearchParams(root?.location?.search);
    setOtp(urlQuery.get("o"));
    setVerificationCode(urlQuery.get("v"));
  }, []);

  if (!verificationCode || !otp) {
    return (
      <div className="mfa-verify-container no-fields">
        <div className="mfa-verify-panel">
          <h3 className="mfa-verify-heading">{"Welcome to Verification Page"}</h3>
        </div>
      </div>
    );
  }

  if (responded) {
    return (
      <div className="mfa-verify-container responded">
        <div className="mfa-verify-panel">
          <h3 className="mfa-verify-heading">{"Thank you for your response."}</h3>
        </div>
      </div>
    );
  }

  const handleDeny = () => {
    denyVerification({
      variables: {
        requestCode: verificationCode,
      },
    }).then(() => {
      setResponded(true);
    }).catch(err => {
      setResponded(true);
    });
  };

  const handleApprove = async () => {
    const apiPath = process.env.BACKEND_URL;

    setApproveLoading(true);
    await fetch(`${apiPath}rest.api/verify/${verificationCode}:${otp}`, {
      method: "GET",
    });

    setResponded(true);
    setApproveLoading(false);
  };

  return (
    <div className="mfa-verify-container">
      <div className="mfa-verify-panel">
        <h3 className="mfa-verify-heading">{"Approve your MFA Request"}</h3>
        <p className="mfa-verify-subheading">{"If you think that you didn't make this request, get in touch with the support team."}</p>
        <div className="mfa-verify-actions-container">
          <Button
            className="deny"
            onClick={handleDeny}
            loading={denyVerificationLoading}
          >
            {"Deny"}
          </Button>
          <Button
            className="approve"
            loading={approveLoading}
            onClick={handleApprove}
          >
            {"Approve"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MfaVerify;
